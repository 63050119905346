import React from "react";
import styled from "styled-components";
import { InlineLink } from "../components/link";
import { Content } from "../components/content";
import { ContentLinks } from "../components/content-links";
import { H1, H3, H4, Text, Label } from "../components/text";
import { Hero } from "../components/hero";
import { Layout } from "../components/layout";
import { Seo } from "../components/seo";
import { Spacer } from "../components/spacer";
import HeroImage from "../images/pages/contact/hero.jpg";
import { Grid } from "../components/grid";
import { Column } from "../components/column";
import FacebookLogo from "../images/social-networks/facebook.svg";
import InstagramLogo from "../images/social-networks/instagram.svg";
import SEOImage from "../images/wvs-india-seo.jpg";

const SocialLinks = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const SocialImage = styled.img`
  width: 30px;
`;

const Index = () => {
  return (
    <Layout>
      <Seo lang="en" title="Contact" image={SEOImage} />
      <Hero image={HeroImage}>
        <H1>Contact</H1>
      </Hero>
      <Content>
        <Grid columns={3}>
          <Column>
            <H3>WVS Centre (Ooty)</H3>
            <br />
            <InlineLink
              href="https://www.facebook.com/groups/161606807221882/"
              title="WVS ITC India"
              rel="noopener noreferrer"
              target="_blank"
            >
              <SocialImage alt="Facebook logo" src={FacebookLogo} />
            </InlineLink>
            <Text>
              <Label>Address:</Label>
              <br />
              WVS India
              <br />
              Gramya Bhavan/RDO-building complex
              <br />
              Aruvankadu
              <br />
              643202 Nilgiris district
              <br />
              Tamil Nadu, India
            </Text>
            <Text>
              <Label>Location on Google Maps:</Label>
              <br />
              <InlineLink
                href="https://goo.gl/maps/tEgYG3h6qyygEkf38"
                title="WVS India"
                rel="noopener noreferrer"
                target="_blank"
              >
                WVS India
              </InlineLink>
            </Text>
            <Text>
              <Label>Email address:</Label>
              <br />
              <InlineLink
                href="mailto:info@wvsindia.org"
                title="info@wvsindia.org"
                rel="noopener noreferrer"
                target="_blank"
              >
                info@wvsindia.org
              </InlineLink>
            </Text>
            <Text>
              <Label>Phone number:</Label>
              <br />
              <InlineLink
                href="tel:+914232204538"
                title="+914232204538"
                rel="noopener noreferrer"
                target="_blank"
              >
                +91 (0) 423 220 4538
              </InlineLink>
            </Text>
            <Text>
              <Label>WVS India Chairman:</Label>
              <br />
              Nigel Otter
            </Text>
            <Text>
              <Label>WVS India Clinical Director:</Label>
              <br />
              Ilona Otter
            </Text>
            <Spacer height={2} />
          </Column>
          <Column>
            <H3>WVS Hicks Centre (Goa)</H3>
            <br />
            <SocialLinks>
              <InlineLink
                href="https://www.facebook.com/wvshicksitc/"
                title="WVS Hicks ITC"
                rel="noopener noreferrer"
                target="_blank"
              >
                <SocialImage alt="Facebook logo" src={FacebookLogo} />
              </InlineLink>
              <Spacer width={1} />
              <InlineLink
                href="https://www.instagram.com/wvsgoa/"
                title="WVS Hicks ITC"
                rel="noopener noreferrer"
                target="_blank"
              >
                <SocialImage alt="Instagram logo" src={InstagramLogo} />
              </InlineLink>
            </SocialLinks>
            <Text>
              <Label>Address:</Label>
              <br />
              WVS Hicks Centre
              <br />
              "Animal Tracks"
              <br />
              Madungo Vaddo
              <br />
              Near Assagao Panchayat
              <br />
              Assagao Bardez Goa
              <br />
              403507 <br />
            </Text>
            <Text>
              <Label>Location on Google Maps:</Label>
              <br />
              <InlineLink
                href="https://goo.gl/maps/JWbPW9679M1ggTAX8"
                title="WVS Hicks"
                rel="noopener noreferrer"
                target="_blank"
              >
                WVS Hicks
              </InlineLink>
            </Text>
            <Text>
              <Label>Phone number:</Label>
              <br />
              <InlineLink
                href="tel:9552557632"
                title="9552557632"
                rel="noopener noreferrer"
                target="_blank"
              >
                9552557632
              </InlineLink>
            </Text>
          </Column>

          <Column>
            <H3>Mission Rabies Goa</H3>
            <br />
            <SocialLinks>
              <InlineLink
                href="https://www.facebook.com/missionrabiesgoa/"
                title="Mission Rabies Goa"
                rel="noopener noreferrer"
                target="_blank"
              >
                <SocialImage alt="Facebook logo" src={FacebookLogo} />
              </InlineLink>
              <Spacer width={1} />
              <InlineLink
                href="https://www.instagram.com/missionrabiesgoa"
                title="WVS Hicks ITC India"
                rel="noopener noreferrer"
                target="_blank"
              >
                <SocialImage alt="Instagram logo" src={InstagramLogo} />
              </InlineLink>
            </SocialLinks>
            <Text>
              <Label>Address:</Label>
              <br />
              Flat B-C2, Veterinary Hospital Complex, Tonca, Miramar, Panaji,
              Goa- 403002
            </Text>
            <Text>
              <Label>Email address:</Label>
              <br />
              <InlineLink
                href="mailto:murugan@missionrabies.in"
                title="murugan@missionrabies.in"
                rel="noopener noreferrer"
                target="_blank"
              >
                murugan@missionrabies.in
              </InlineLink>
            </Text>
            <Text>
              <Label>Rabies hotline:</Label>
              <br />
              <InlineLink
                href="tel:7744029586"
                title="7744029586"
                rel="noopener noreferrer"
                target="_blank"
              >
                7744029586
              </InlineLink>
            </Text>
          </Column>
        </Grid>

        <Spacer height={3} />

        <H4>How to get to WVS Ooty</H4>
        <Text>
          Depending on the direction you are coming from, you should arrive to
          Coimbatore or to Bangalore first.
        </Text>
        <ul>
          <li>
            From Bangalore, take an overnight bus to Ooty (8 hours) and from
            Ooty bus station, take a local bus to Coonoor and ask the driver to
            stop at Kanigarajnagar – village bus stop. ITC is 50meters ahead, on
            the right side of the road.
          </li>
          <li>
            From Coimbatore, take a bus to Ooty and get off about 20 min before
            Ooty (2km after Aruvankadu Cordite factory) in the same
            Kanigarajnagar – village.
          </li>
        </ul>

        <H4>How to get to WVS Hicks, Goa</H4>
        <Text>
          Depending on the direction you are coming from, you could arrive in
          North or South Goa.
        </Text>
        <ul>
          <li>
            From North Goa (Thivim RailwayStation, Mapusa or Panjim Bus Stand),
            take a local bus/ taxi to Assagao.
          </li>
          <li>
            From South Goa (Dabolim Airport or Margao Railway Station), take a
            bus to the nearest city (Mapusa or Panjim), and then a local bus/
            taxi to Assagao.
          </li>
        </ul>

        <Text>
          The Hicks centre is located near the Assagao Village Panchayat. The
          driveway is a mud/ un-tarred road (ask for Animal Rescue).
        </Text>
      </Content>
      <ContentLinks />
    </Layout>
  );
};

export default Index;
